import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

// 画像ファイルパスをプロパティに取るようなコンポーネントを定義
export default ({ filepath }) => (

  // ページじゃないコンポーネントでもGraphQLが使えるようにStaticQueryタグを使う
  <StaticQuery

    query={graphql`
      query {
        images: allFile(filter: {ext: {regex: "/gif|jpg|png|svg/"}}) {
          edges {
            node {
              relativePath
              name
              extension
              publicURL
              childImageSharp {
                sizes(maxWidth: 800) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
        }
      }
    `}

    // 全画像情報がdataに代入されている
    render={(data) => {

      // 指定した画像ファイルパス（コンポーネントのプロパティ）と
      // 一致するgatsby-image用の情報を取得
      const image = data.images.edges.find(n => {
        return n.node.relativePath.includes(filepath)
      })


      if (!image) return


      if (image.node.extension === 'svg') {
        return <img src={image.node.publicURL} alt="" />
      } else {
        return <Img sizes={image.node.childImageSharp.sizes} />
      }

    }}
  />
)